<template>
  <div>
    <template v-if="selectedValue">
      <app-text-field
        label="Selecionar Transação"
        :value="selectedValue.name"
        readonly
        v-bind="$attrs"
        @click="open()"
        prepend-inner-icon="mdi-magnify"
        @keypress="open()"
      />
    </template>
    <template v-else>
      <div id="acc" @click.stop="open()">
        <app-text-field
          v-bind="$attrs"
          label="Selecionar Transação"
          readonly
          @click="open()"
          prepend-inner-icon="mdi-magnify"
        />
      </div>
    </template>
    <v-dialog
      v-model="dialog"
      max-width="1200px"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between" style="width: 100%">
            <div>
              <span>Selecionar Transação</span>
            </div>
          </div>
        </v-card-title>
        <v-card-subtitle class="d-flex"> </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col class="d-flex align-center" cols="6">
              <app-text-search-field
                @filter="showFilter = !showFilter"
                v-model="searchForm.description"
              />
              <v-btn
                @click="search()"
                class="text-capitalize ml-5"
                color="primary"
                large
              >
                <app-icon>search</app-icon>
              </v-btn>
            </v-col>
          </v-row>
          <template v-if="showFilter">
            <v-row class="d-flex align-center">
              <v-col>
                <AccountPlanSearch
                  v-model="searchForm.accountPlan"
                  @input="setAccountPlan($event)"
                />
              </v-col>

              <v-col>
                <app-text-field
                  v-model="searchForm.start_date"
                  label="Data Inicial"
                  type="date"
                />
              </v-col>
              <v-col>
                <app-text-field
                  v-model="searchForm.end_date"
                  label="Data Final"
                  type="date"
                />
              </v-col>
              <v-col cols="4" class="d-flex justify-center">
                <v-chip-group v-model="searchForm.status" column multiple>
                  <v-chip small filter :value="'paid'" color="primary">
                    Confirmados
                  </v-chip>
                  <v-chip small filter :value="'canceled'">Cancelados</v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  class="text-capitalize"
                  @click="clearFilter()"
                  outlined
                  text
                >
                  Limpar Filtro
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="searchResults.data"
                :items-per-page="50"
                hide-default-footer
                @click:row="setSelectedValue($event)"
              >
                <template v-slot:[`item.description`]="{ item }">
                  <account-transaction-label :account-transaction="item" />
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <status-chip :value="item.status" />
                </template>

                <template v-slot:[`item.date`]="{ item }">
                  {{ $format.dateBr(item.date) }}
                </template>
                <template v-slot:[`item.payment_method`]="{ item }">
                  <app-payment-method-chip :value="item.payment_method" />
                </template>
                <template v-slot:[`item.value`]="{ item }">
                  <app-money-label
                    :value="item.value"
                    :canceled="item.status == 'canceled'"
                  />
                </template>
                <template v-slot:[`item.balance`]="{ item }">
                  <app-money-label
                    :value="item.balance"
                    :canceled="item.status == 'canceled'"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn class="text-capitalize" text @click="dialog = false">
            Voltar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import AccountTransactionTotals from "@/components/account/sections/AccountTransactionTotals.vue";
import ReceiveSaleCard from "@/components/account/sections/ReceiveSaleCard.vue";
import AccountTransactionLabel from "@/components/account/sections/AccountTransactionLabel.vue";
import AccountTransactionDialog from "@/components/account/sections/AccountTransactionDialog";
import AccountSelect from "@/components/account/ui/AccountSelect.vue";
import StoreAccountTransactionForm from "@/components/account/form/StoreAccountTransactionForm.vue";
import TransferAccountTransactionForm from "@/components/account/form/TransferAccountTransactionForm.vue";
import paymentMethods from "@/enums/paymentMethods";
import SelectPendingTransactions from "@/components/account/sections/SelectPendingTransactions.vue";
import PendingTransactionAlert from "@/components/account/sections/PendingTransactionAlert.vue";
import AccountPlanSearch from "@/components/account-plan/ui/AccountPlanSearch.vue";
import SelectSalesReceivable from "@/components/sales/sections/SelectSalesReceivable.vue";
import PayBillReceivableDialog from "@/components/account/sections/PayBillReceivableDialog.vue";
export default {
  components: {
    AccountSelect,
    AccountTransactionLabel,
    AccountTransactionDialog,
    AccountTransactionTotals,
    StoreAccountTransactionForm,
    TransferAccountTransactionForm,
    SelectPendingTransactions,
    PendingTransactionAlert,
    AccountPlanSearch,
    SelectSalesReceivable,
    ReceiveSaleCard,
    PayBillReceivableDialog,
  },

  props: {
    value: {},
    valueLabel: {},
    accountId: {},
  },

  data: () => ({
    showFilter: false,
    url: "account/account-transaction",
    headers: [
      { text: "Descrição", value: "description", sortable: false },
      {
        text: "Data",
        value: "date",
        sortable: false,
        align: "center",
        width: "12%",
      },
      {
        text: "Meio Pg",
        value: "payment_method",
        sortable: false,
        align: "center",
        width: "12%",
      },
      {
        text: "Valor",
        value: "value",
        sortable: false,
        align: "end",
        width: "12%",
      },
      {
        text: "Saldo",
        value: "balance",
        sortable: false,
        align: "end",
        width: "12%",
      },
    ],
    searchResults: {},

    selectedValue: {
      name: null,
    },

    dialog: false,

    isLoading: false,

    searchForm: {
      searchText: null,
    },

    itemIndex: null,
  }),

  computed: {
    // accountPlans() {
    //   return this.$store.state.accountPlan.accountPlans;
    // },
  },

  watch: {
    value() {
      this.selectedValue = this.value;
    },
  },

  created() {
    this.selectedValue = this.value;
  },

  methods: {
    async open() {
      await this.search();

      this.dialog = true;
    },

    async search() {
      this.isLoading = true;

      this.searchForm.account_id = this.accountId;

      await this.$http.index(this.url, this.searchForm).then((response) => {
        this.searchResults = response.accountTransactions;
      });

      this.isLoading = false;
    },

    setSelectedValue(selectedValue) {
      this.selectedValue = selectedValue;

      this.$emit("input", selectedValue);

      this.dialog = false;
    },

    clear() {
      this.selectedValue = null;
      this.clearFilter();
    },
    setAccountPlan(accountPlan) {
      this.searchForm.account_plan_id = accountPlan.id;
    },
    clearFilter() {
      this.searchForm.accountPlan = null;
      this.searchForm.account_plan_id = null;
      this.searchForm.start_date = null;
      this.searchForm.end_date = null;
      this.searchForm.description = null;
      this.search();
    },
  },
};
</script>
