<template>
  <app-status-chip :statuses="statuses" :value="value" />
</template>

<script>
export default {
  props: {
    value: {},
  },

  data: () => ({
    statuses: {
      pending : {
        text: "Pendente",
        color: "disabled",
      },
      reconciled: {
        text: "Conciliado",
        color: "primary",
      },
      ignored: {
        text: "Ignorado",
        color: "secondary",
      },
    },
  }),
};
</script>

<style>
</style>