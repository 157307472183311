<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-card outlined>
          <v-card-text class="d-flex">
            <AccountIcon :account="account" class="ml-1 mr-6" />
            {{ account.name }}
            <small class="text--secondary ml-2"
              >Ag: {{ account.bank_agency }} | CC: {{ account.bank_account }}
            </small>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <app-text-search-field @input="handleSearchInput($event)" dense />
      </v-col>
      <v-col class="text-right">
        <app-file-field
          :auto-clear="true"
          label="Importar Extrato"
          color="primary"
          accept=".ofx"
          url="account/bank-statement-transactions"
          @input="select()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-chip-group
          @change="select()"
          v-model="searchParams.status"
          column
          multiple
        >
          <template v-for="status in statuses">
            <v-chip
              small
              filter
              :value="status.value"
              :color="status.color"
              :key="status.value"
            >
              {{ status.text }}
            </v-chip>
          </template>
        </v-chip-group>
      </v-col>
      <v-col cols="3">
        <app-text-field
          v-model="searchParams.date_start"
          label="Data Inicial"
          type="date"
          @input="select()"
        />
      </v-col>
      <v-col cols="3">
        <app-text-field
          v-model="searchParams.date_end"
          label="Data Final"
          type="date"
          @input="select()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="bank_statement_transactions.data"
          :items-per-page="-1"
          hide-default-footer
          disable-sort
        >
          <template v-slot:[`item.value`]="{ item }">
            {{ $format.moneyBr(item.value) }}
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{ $format.dateBr(item.date) }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <BankStatementTransactionChip :value="item.status" />
          </template>
          <template v-slot:[`item.icon`]="">
            <app-icon class="my-6">mdi-arrow-right</app-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item, index }">
            <template v-if="item.status == 'pending'">
              <template
                v-if="
                  item.associate_account_transactions &&
                  item.associate_account_transactions.length > 0
                "
              >
                <template
                  v-for="(
                    associateAccountTransaction, templateIndex
                  ) in item.associate_account_transactions"
                >
                  {{ associateAccountTransaction.description }} |
                  {{ $format.moneyBr(associateAccountTransaction.value) }}
                  <br :key="templateIndex" />
                </template>
                <v-btn
                  class="my-2 mr-2"
                  small
                  @click="
                    associateAccountTransaction(
                      item,
                      item.associate_account_transactions
                    )
                  "
                  color="primary"
                  :disabled="
                    !canAssociateAccountTransaction(
                      item,
                      item.associate_account_transactions
                    )
                  "
                >
                  Associar
                </v-btn>
                <v-btn
                  class="my-2 mr-2"
                  small
                  @click="handleSearch(index)"
                  color="disabled"
                >
                  Pesquisar outro
                </v-btn>
                <v-btn
                  class="my-2 mr-2"
                  small
                  @click="clearAssociateAccountTransaction(index)"
                  color="disabled"
                >
                  Remover
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  class="mr-2"
                  @click="createTransaction(item)"
                  color="primary"
                  small
                >
                  Cadastrar
                </v-btn>
                <v-btn
                  class="mr-2"
                  color="disabled"
                  small
                  @click="handleSearch(index)"
                >
                  Pesquisar
                </v-btn>
                <v-btn
                  small
                  v-if="item.status == 'pending'"
                  @click="ignoreTransaction(item.id)"
                  color="secondary"
                >
                  Ignorar
                </v-btn>
              </template>
            </template>

            <template
              v-if="item.status == 'reconciled' && item.account_transactions"
            >
              <template
                v-for="(
                  account_transaction, index
                ) in item.account_transactions"
              >
                {{ account_transaction.description }} |{{
                  $format.moneyBr(account_transaction.value)
                }}
                <br :key="index" />
              </template>
            </template>

            <template v-if="item.status == 'ignored'">
              <span class="text--disabled mr-5">Ignorado</span>
            </template>

            <v-tooltip v-if="item.status != 'pending'" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="restoreStatement(item.id)"
                  color="secondary"
                >
                  <v-icon>mdi-undo</v-icon>
                </v-btn>
              </template>
              <span> Reverter Conciliação </span>
            </v-tooltip>
          </template>
        </v-data-table>
        <app-pagination
          @click="paginate($event)"
          :data="bank_statement_transactions"
        />
      </v-col>
    </v-row>
    <StoreAccountTransactionForm
      ref="StoreAccountTransactionForm"
      @store="select()"
    />
    <AccountTransactionSearch
      ref="AccountTransactionSearch"
      @input="addSearchResult($event)"
      :accountId="searchParams.account_id"
      class="d-none"
    />
  </div>
</template>

<script>
import BankStatementTransactionChip from "@/components/bank-statement-transaction/sections/BankStatementTransactionChip.vue";
import StoreAccountTransactionForm from "@/components/account/form/StoreAccountTransactionForm.vue";
import AccountTransactionSearch from "@/components/account/ui/AccountTransactionSearch.vue";
import AccountSelect from "@/components/account/ui/AccountSelect.vue";
import AccountIcon from "@/components/account/sections/AccountIcon";

export default {
  components: {
    StoreAccountTransactionForm,
    BankStatementTransactionChip,
    AccountTransactionSearch,
    AccountSelect,
    AccountIcon,
  },

  data() {
    return {
      searchingIndex: null,

      bank_statement_transactions: {
        data: [
          {
            transactions: [],
          },
        ],
      },
      account: {},

      searchParams: {
        account_id: null,
        date_start: null,
        date_end: null,
        page: 1,
      },

      statuses: {
        pending: {
          text: "Pendente",
          value: "pending",
          color: "disabled",
        },
        reconciled: {
          text: "Conciliado",
          value: "reconciled",
          color: "primary",
        },
        ignored: {
          text: "Ignorado",
          value: "ignored",
          color: "secondary",
        },
      },

      headers: [
        { text: "Data", value: "date", align: "left", width: "5%" },
        // { text: "Tipo", value: "type" },
        { text: "Descrição", value: "description", width: "30%" },
        // { text: "Nº Conta", value: "account_number", align: "center" },
        { text: "Valor", value: "value", align: "right", width: "10%" },
        { value: "icon", align: "center", width: "5%" },
        { value: "actions", sortable: false, width: "40%" },
        { text: "Status", value: "status", align: "center", width: "10%" },
      ],
    };
  },

  created() {
    this.searchParams.account_id = this.$route.query.account_id;

    this.getAccount();
  },

  methods: {
    select() {
      this.$loading.start();
      this.$http
        .index("account/bank-statement-transactions", this.searchParams)
        .then((response) => {
          this.$loading.finish();
          this.bank_statement_transactions = response.statement_transactions;

          this.selectAccountTransactions();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    getAccount() {
      this.$http
        .show("account/account", this.searchParams.account_id)
        .then((response) => {
          this.account = response.account;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    paginate(page) {
      this.searchParams.page = page;
      this.select();
    },

    handleSearchInput(value) {
      this.searchParams.text = value;
      this.select();
    },

    selectAccountTransactions() {
      // get max date of bank_statement_transactions.data.date
      let start_date = this.bank_statement_transactions.data.reduce(
        (min, p) => (p.date < min ? p.date : min),
        this.bank_statement_transactions.data[0].date
      );
      let end_date = this.bank_statement_transactions.data.reduce(
        (max, p) => (p.date > max ? p.date : max),
        this.bank_statement_transactions.data[0].date
      );

      let accountTransactionsSearchParams = {
        page: 1,
        start_date: start_date,
        end_date: end_date,
        account_id: this.searchParams.account_id,
        status: ["paid"],
        paginate: 999999,
      };

      this.$http
        .index("account/account-transaction", accountTransactionsSearchParams)
        .then((response) => {
          // this.$refs.PendingTransactionAlert.select();
          this.accountTransactions = response.accountTransactions;
          // this.accountTransactionsTotals = response.accountTransactionsTotals;
          // this.$store.commit("app/hideLoading");
          this.compareTransactions();
        })
        .catch((error) => {});
    },

    compareTransactions() {
      var bank_statement_transactions = JSON.parse(
        JSON.stringify(this.bank_statement_transactions.data)
      );

      for (var bank_statement_transaction of bank_statement_transactions) {
        bank_statement_transaction.associate_account_transactions = [];

        if (bank_statement_transaction.status == "pending") {
          let probable_transactions = this.findProbableTransaction(
            bank_statement_transaction
          );

          if (probable_transactions) {
            bank_statement_transaction.associate_account_transactions =
              probable_transactions;
          }
        }
      }

      this.bank_statement_transactions.data = bank_statement_transactions;
    },

    findProbableTransaction(bank_statement_transactions) {
      return this.accountTransactions.data.filter(
        (account_transaction) =>
          account_transaction.value == bank_statement_transactions.value &&
          account_transaction.date == bank_statement_transactions.date
      );
    },

    canAssociateAccountTransaction(
      bank_statement_transaction,
      account_transactions
    ) {
      return (
        this.$calc.sum(account_transactions, "value") ==
        bank_statement_transaction.value
      );
    },

    associateAccountTransaction(
      bank_statement_transaction,
      account_transactions
    ) {
      this.$http
        .update(
          "account/bank-statement-transactions",
          bank_statement_transaction.id,
          { accountTransactions: account_transactions }
        )
        .then((response) => {
          this.select();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    createTransaction(statement_transaction) {
      if (statement_transaction.value > 0) {
        var type = "input";
      } else {
        var type = "out";
      }

      this.$refs.StoreAccountTransactionForm.open(
        type,
        statement_transaction.account,
        statement_transaction
      );
    },

    ignoreTransaction(statement_transaction_id) {
      this.$http
        .update(
          "account/bank-statement-transactions/ignore",
          statement_transaction_id
        )
        .then((response) => {
          this.select();
        })
        .catch((error) => {});
    },

    handleSearch(index) {
      this.searchingIndex = index;

      this.$refs.AccountTransactionSearch.open();
    },

    addSearchResult(data) {
      let bank_statement_transactions = JSON.parse(
        JSON.stringify(this.bank_statement_transactions.data)
      );

      let bank_statement_transaction =
        bank_statement_transactions[this.searchingIndex];

      bank_statement_transaction.associate_account_transactions.push(data);

      this.$set(
        this.bank_statement_transactions.data,
        this.searchingIndex,
        bank_statement_transaction
      );

      this.searchingIndex = null;
    },

    clearAssociateAccountTransaction(index) {
      let bank_statement_transactions = JSON.parse(
        JSON.stringify(this.bank_statement_transactions.data)
      );

      let bank_statement_transaction = bank_statement_transactions[index];

      bank_statement_transaction.associate_account_transactions = [];

      this.$set(
        this.bank_statement_transactions.data,
        index,
        bank_statement_transaction
      );
    },
    restoreStatement(statement_transaction_id) {
      this.$http
        .destroy(
          "account/bank-statement-transactions",
          statement_transaction_id
        )
        .then((response) => {
          this.select();
        })
        .catch((error) => {});
    },
  },
};
</script>

<style></style>
